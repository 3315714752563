<template>
  <div class="row">
    <div class="col-12">
      <div class="card card-custom gutter-b example example-compact">
        <div
          class="card-header d-flex align-items-center justify-content-between"
        >
          <div class="card-title">
            <h3 class="card-label">Gas Uskuna Hujjatlari Malumoti</h3>
          </div>
        </div>
        <div class="card-body">
          <v-container fluid>
            <v-row>
              <v-col cols="12" md="3" class="pl-0">
                <v-text-field
                  v-if="gasEquipsDetails.truck_info !== undefined"
                  :value="gasEquipsDetails.truck_info.truck_number"
                  disabled
                  dense
                  label="Mashina raqami"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3" class="pl-0">
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="gasEquipsDetails.monitored | formatDate"
                      label="Boshlangan sana"
                      disabled
                      append-icon="event"
                      readonly
                      dense
                      v-bind="attrs"
                      outlined
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="monitored"
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="3" class="pl-0">
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  disabled
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="gasEquipsDetails.expires | formatDate"
                      label="Tugash sanasi"
                      disabled
                      append-icon="event"
                      readonly
                      dense
                      v-bind="attrs"
                      v-on="on"
                      outlined
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="expires"
                    @input="menu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="3" class="pl-0">
                <v-text-field
                  v-model="gasEquipsDetails.reg_number"
                  disabled
                  dense
                  label="Reg raqami"
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div>
    </div>
  </div>
</template>
<style></style>

<script>
import Swal from 'sweetalert2'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
// import debounce from "debounce";
import { required } from 'vuelidate/lib/validators'
export default {
  data() {
    return {
      menu: false,
      memu2: false,
      monitored_by: '',
      expires: '',
      monitored: '',
      reg_number: '',
      truck: ''
    }
  },
  validations: {
    expires: {
      required
    },
    monitored: {
      required
    },
    reg_number: {
      required
    },
    truck: {
      required
    }
  },
  created() {
    this.$store.dispatch('getGasEquipsDetails', this.$route.params.id)
  },
  filters: {
    formatDate: function (val) {
      if (val !== null && val !== undefined) {
        return val.split('-').reverse().join('-')
      }
    }
  },
  computed: {
    gasEquipsDetails() {
      const data = this.$store.state.requests.gasEquipsDetails
      return data
    },
    isLoading() {
      return this.$store.state.requests.isLoading
    },
    truckErrors() {
      const errors = []
      if (!this.$v.truck.$dirty) return errors

      !this.$v.truck.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    reg_numberErrors() {
      const errors = []
      if (!this.$v.reg_number.$dirty) return errors

      !this.$v.reg_number.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    monitoredErrors() {
      const errors = []
      if (!this.$v.monitored.$dirty) return errors

      !this.$v.monitored.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    expiresErrors() {
      const errors = []
      if (!this.$v.expires.$dirty) return errors

      !this.$v.expires.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Gas Uskuna Hujjatlari Malumoti' }
    ])
  },
  methods: {
    save() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
        Swal.fire({
          title: 'Xato',
          text: "Ma'lumotlar yuborilmadi!",
          icon: 'error',
          confirmButtonClass: 'btn btn-secondary'
        })
      } else {
        this.submitStatus = 'PENDING'
        this.submitStatus = 'OK'
        const data = {
          truck: this.truck.id,
          monitored: this.monitored,
          reg_number: this.reg_number,
          expires: this.expires
        }
        this.$store.dispatch('updateGasEquips', {
          data: data,
          id: this.$route.params.id
        })
        this.truck = ''
        this.monitored = ''
        this.reg_number = ''
        this.expires = ''
        this.$v.$reset()
      }
    }
  }
}
</script>
